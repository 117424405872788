<template>
  <!-- eslint-disable -->
  <decorator-icon v-bind="attrs">
    <path
        d="M23.9879 14.9996C23.6241 14.9996 23.2361 15.1451 22.9693 15.4361L8.41834 29.9871C7.86055 30.5449 7.86055 31.4664 8.41834 32.0485C8.97613 32.6062 9.89769 32.6062 10.4797 32.0485L23.9879 18.5161L37.5203 32.0485C38.0781 32.6062 38.9996 32.6062 39.5817 32.0485C40.1395 31.4907 40.1395 30.5691 39.5817 29.9871L25.0307 15.4361C24.7397 15.1451 24.3517 14.9996 23.9879 14.9996Z"
        fill="inherit"/>
  </decorator-icon>
</template>

<script>
import DecoratorIcon from '../decorator/dp-icon-decorator'
import MixinIcon from '../mixin/dp-icon-mixin'

/**
 * @group Components-icon-svg
 * Standard components
 */
export default {
  name: 'dp-chevron-up-icon',
  mixins: [MixinIcon],
  components: {
    DecoratorIcon
  },
  data() {
    return {
      viewBox: '0 0 48 48'
    }
  }
}
</script>
